import React, { useContext } from 'react';
import pt from 'prop-types';
import { getLanguageName } from '../../../../helpers/getLanguageName';
import Text from '../../../ui/text';
import Image from '../../../ui/image';
import Icon from '../../../ui/icon';
import Link from '../../../ui/link';
import * as styles from './styles.module.scss';
import { getCopy } from '../../../../helpers/copy';
import { MicrocopyContext } from '../../../../context/microcopy.context';

const EventsDetailCompanyLink = ({ logo, name, country, city, id, slug }) => {
  const microcopy = useContext(MicrocopyContext);

  return (
    <div className={styles.companyLink}>
      <Text as="span" type="infotext" style="semibold" className={styles.label}>
        {getCopy('label.company', microcopy)}
      </Text>
      <Link
        link={{ linkToPage: slug }}
        className={styles.wrapper}
        useGatsbyLink={false}
      >
        {logo && (
          <Image
            image={{ url: logo, description: name }}
            className={styles.imageWrapper}
            imgClassName={styles.image}
            disableGatsbyImage
          />
        )}
        <div className={styles.company}>
          <Text as="span" type="h3" style="semibold">
            {name}
          </Text>
          {city && country && (
            <Text as="span" type="p1">
              {city}, {getLanguageName(country)}
            </Text>
          )}
        </div>
        <Icon name="arrow-right" size="2x" />
      </Link>
    </div>
  );
};

EventsDetailCompanyLink.propTypes = {
  id: pt.number.isRequired,
  name: pt.string.isRequired,
  logo: pt.string,
  country: pt.string,
  city: pt.string,
};

EventsDetailCompanyLink.defaultProps = {
  logo: '',
  country: '',
  city: '',
};

export default EventsDetailCompanyLink;
