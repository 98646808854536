import React, { useContext } from 'react';
import pt from 'prop-types';
import Fact from '../../../ui/fact';
import * as styles from './styles.module.scss';
import { MicrocopyContext } from '../../../../context/microcopy.context';
import { getCopy } from '../../../../helpers/copy';
import { useLocale } from '../../../../context/locale.context';
import { getFormattedDate } from '../../../../helpers/getFormattedDate';

const EventsDetailsTable = ({ date, location, city, country }) => {
  const microcopy = useContext(MicrocopyContext);
  const { locale } = useLocale();

  return (
    <div className={styles.table}>
      {date && (
        <Fact
          label={getCopy('fact.date', microcopy)}
          value={getFormattedDate(date, 'EEEE, d LLLL y', locale)}
        />
      )}
      {location && (
        <Fact label={getCopy('fact.location', microcopy)} value={location} />
      )}
      {city && <Fact label={getCopy('fact.city', microcopy)} value={city} />}
      {country && (
        <Fact label={getCopy('fact.country', microcopy)} value={country} />
      )}
    </div>
  );
};

EventsDetailsTable.propTypes = {
  date: pt.string,
  location: pt.string,
  city: pt.string,
  country: pt.string,
};

EventsDetailsTable.defaultProps = {
  date: '',
  location: '',
  city: '',
  country: '',
};

export default EventsDetailsTable;
