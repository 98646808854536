import React from 'react';
import pt from 'prop-types';
import { combine } from '/src/helpers/styles';
import Text from '../text';
import * as styles from './styles.module.scss';

const Fact = ({ className, label, value, labelClass }) => (
  <div className={combine(styles.factWrapper, className)}>
    <div className={combine(styles.labelWrapper, labelClass)}>
      <Text as="span" type="infotext">
        {label}
      </Text>
    </div>
    <div>
      <Text as="span" type="infotext" variant="semibold">
        {value}
      </Text>
    </div>
  </div>
);

Fact.propTypes = {
  label: pt.string.isRequired,
  value: pt.string.isRequired,
  className: pt.string,
  labelClass: pt.string,
};

Fact.defaultProps = {
  className: '',
  labelClass: '',
};

export default Fact;
