import React, { useEffect, useState } from 'react';
import Container from '../../modules/container';
import Layout from '../../modules/layout';
import EventsDetail from '../../components/event-detail';
import * as styles from './styles.module.scss';
import { navigate } from 'gatsby';

import { getAPIRoot } from '../../helpers/fetch';

const EventDetailTemplate = ({ eventData }) => {
  if (!eventData) return null;

  return (
    <Layout
      title={eventData.title}
      description={eventData.description}
      slug={eventData.slug}
    >
      <div className={styles.wrapper}>
        <Container small hasWhiteBg>
          <EventsDetail data={eventData} />
        </Container>
      </div>
    </Layout>
  );
};

export default EventDetailTemplate;
