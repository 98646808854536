import React from 'react';
import pt from 'prop-types';
import { format } from 'date-fns';
import Text from '../../../ui/text';
import SocialShare from '../../../ui/socialShare';
import * as styles from './styles.module.scss';
import { getDynamicPageLink } from '../../../../helpers/getDynamicPageLink';
import { DYNAMIC_PAGE_TYPES } from '../../../../utils/constants';

const EventsDetailHeader = ({ category, title, timestamp, id }) => (
  <div className={styles.header}>
    <div className={styles.metaWrapper}>
      <Text as="span" type="infotext" variant="condensed">
        {timestamp && format(new Date(timestamp), 'EEEE, d LLLL y')}
      </Text>
      <SocialShare
        displayLabel
        shareURL={getDynamicPageLink(
          DYNAMIC_PAGE_TYPES.EVENT_DETAIL,
          title,
          id
        )}
        isArticleDetail
      />
    </div>
    <div className={styles.textWrapper}>
      <Text as="span" type="articleType" className={styles.category}>
        {category}
      </Text>
      <Text as="h1" type="h1" variant="editorial" style="semibold">
        {title}
      </Text>
    </div>
  </div>
);

EventsDetailHeader.propTypes = {
  category: pt.string.isRequired,
  title: pt.string.isRequired,
  timestamp: pt.string.isRequired,
  id: pt.number.isRequired,
};

export default EventsDetailHeader;
