import React from 'react';
import pt from 'prop-types';
import EventsDetailHeader from './components/header';
import EventsDetailsTable from './components/table';
import EventsDetailCompanyLink from './components/companyLink';
import EventsDetailContent from './components/content';
import * as styles from './styles.module.scss';

const EventsDetail = ({ data }) => (
  <>
    <EventsDetailHeader
      category={data.category}
      title={data.title}
      timestamp={data.timestamp}
      id={data.id}
    />
    <div className={styles.textWrapper}>
      <EventsDetailsTable
        country={data.country}
        date={data.startDate}
        city={data.city}
        location={data.location}
      />
      <EventsDetailContent
        image={data.image}
        description={data.description}
        link={data.url}
        id={data.id}
      />
      <EventsDetailCompanyLink
        id={data.companyProfile?.id}
        logo={data.companyProfile?.logo}
        name={data.companyProfile?.companyName}
        city={data.companyProfile?.city}
        country={data.companyProfile?.country}
        slug={data.companyProfile?.slug}
      />
    </div>
  </>
);

EventsDetail.propTypes = {
  data: pt.object.isRequired,
};

export default EventsDetail;
