import { by639_1 } from 'iso-language-codes';

export const getLanguageName = (langCode) => {
  if (langCode === 'ZH-CN') {
    return 'Chinese, simplified';
  } else if (langCode === 'ZH-TW') {
    return 'Chinese, traditional';
  }
  return by639_1[langCode.toLowerCase()]?.name;
};
